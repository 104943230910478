'use client';

import Badge from '@/components/ui/Badge/Badge';
import ToggleIconButton from '@/components/ui/ToggleIconButton/ToggleIconButton';
import { Session } from 'next-auth';
import { useSession } from 'next-auth/react';
import Link from 'next/link';
import ActionButton from '../../form/ActionButton/ActionButton';
import { useIsMenuOpenedAtom } from '../CommonMenu/CommonMenu';
import styles from './FamikujiHeader.module.scss';

function FamikujiHeader({
  initialSessionData,
  isOptionNotEntered = false,
  hasPendingEntry = false,
  hasAlertEcItem = false,
}: {
  initialSessionData?: ReturnType<typeof useSession>['data'];
  isOptionNotEntered?: boolean;
  hasPendingEntry?: boolean;
  hasAlertEcItem?: boolean;
}) {
  const { data: sessionData = initialSessionData } = useSession();
  const [isMenuOpenedValue, setIsMenuOpened] = useIsMenuOpenedAtom();

  const showBadge = (session: Session) => {
    return session.user.unreadMessageBoxCount > 0 || isOptionNotEntered || hasPendingEntry || hasAlertEcItem;
  };
  return (
    <header className={styles.componentStyle}>
      <div className={styles.container}>
        <div className={styles.dummy} />
        {sessionData ? (
          <div className={styles.menuWrapper}>
            <ToggleIconButton isActive={isMenuOpenedValue} onClick={() => setIsMenuOpened(!isMenuOpenedValue)} />
            {showBadge(sessionData) && (
              <div className={styles.menuBadge}>
                <Badge color='primary' />
              </div>
            )}
          </div>
        ) : (
          <div className={styles.auth}>
            <Link href={`/auth/signin?callbackUrl=${location.href}`} className={styles.loginLink}>
              ログイン
            </Link>
            <div className={styles.signupButton}>
              <ActionButton
                color='primary'
                size='xSmall'
                onClick={() => {
                  location.href = `${process.env.NEXT_PUBLIC_MAIN_URL}/${process.env.NEXT_PUBLIC_SERVICE_ID}/auth/signup`;
                }}
              >
                会員登録
              </ActionButton>
            </div>
          </div>
        )}
      </div>
    </header>
  );
}

export default FamikujiHeader;
