'use client';

import ActionButton from '@/components/form/ActionButton/ActionButton';
import FormCheckbox from '@/components/form/FormCheckbox/FormCheckbox';
import ModalBase from '@/components/ui/ModalBase/ModalBase';
import { swal } from '@/lib/sweetalert';
import layoutStyles from '@/styles/modules/layout.module.scss';
import utilsStyles from '@/styles/modules/utils.module.scss';
import { getCookieValue, setCookie } from '@/utils/cookie';
import { pickErrorMessages } from '@/utils/functions';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { postNewsLetter } from '../../api/postNewsLetter';

function SetNewsLetterModal({ enableNewsLetterPermission }: { enableNewsLetterPermission: boolean }) {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { getValues, handleSubmit, control } = useForm({
    mode: 'onChange',
    defaultValues: {
      setNewsLetterPermission: ['setNewsLetterPermission'],
    },
  });
  const cookieKey = 'recentOpenNewsLetterModal';

  useEffect(() => {
    const recentOpenNewsLetterModal = getCookieValue(cookieKey);
    if (!recentOpenNewsLetterModal && !enableNewsLetterPermission) {
      // biome-ignore lint/suspicious/noExplicitAny: <explanation>
      (window as any).dataLayer = (window as any).dataLayer || [];
      // biome-ignore lint/suspicious/noExplicitAny: <explanation>
      const dataLayer = (window as any).dataLayer;
      // カスタムイベント
      dataLayer.push({
        event: 'modal_view',
        modalName: 'mail_magazine_opt_in',
      });
      setIsOpen(true);
    }
  }, []);

  const submit = async () => {
    try {
      setIsLoading(true);
      const checked = getValues('setNewsLetterPermission').includes('setNewsLetterPermission');
      // カスタムイベント
      // biome-ignore lint/suspicious/noExplicitAny: <explanation>
      (window as any).dataLayer = (window as any).dataLayer || [];
      // biome-ignore lint/suspicious/noExplicitAny: <explanation>
      const dataLayer = (window as any).dataLayer;
      dataLayer.push({
        event: 'modal_button_click',
        buttonName: 'mail_magazine_opt_in_button',
        checkboxChecked: checked ? 'true' : 'false',
      });
      await postNewsLetter({
        permission: checked ? 2 : 0,
      });
      // 30日後に再表示
      setCookie(cookieKey, 'true', 60 * 60 * 24 * 30);
      setIsOpen(false);
      setIsLoading(false);
    } catch (error) {
      await swal.messages({ messages: pickErrorMessages(error) });
      setIsLoading(false);
    }
  };

  const submitError = () => {
    swal.invalid();
  };

  return (
    <ModalBase
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
      showCloseButton={false}
    >
      <div className={layoutStyles.modalPadding}>
        <div className={layoutStyles.modalTitle}>
          本サービスご利用にあたっての
          <br />
          ユーザ情報の登録
        </div>
        <p className={`${utilsStyles.mBSmall} ${utilsStyles.textXSmall}`}>
          ご登録メールアドレス宛にお得なキャンペーンなどの情報をお送りいたします。不要な方は下記チェックを外してください。
        </p>
        <FormCheckbox
          name='setNewsLetterPermission'
          items={[
            {
              value: 'setNewsLetterPermission',
              child: <>お得なキャンペーン情報を受けとる</>,
            },
          ]}
          control={control}
        />
        <div className={utilsStyles.mtSmall}>
          <ActionButton
            isLoading={isLoading}
            onClick={handleSubmit(submit, submitError)}
            size='fullWidth'
            color='primary'
          >
            登録
          </ActionButton>
        </div>
      </div>
    </ModalBase>
  );
}

export default SetNewsLetterModal;
