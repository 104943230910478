import InputCheckbox from '@/components/input/InputCheckbox/InputCheckbox';
import formStyle from '@/styles/modules/form.module.scss';
import { useController } from 'react-hook-form';
import type { DeepMap, FieldError, FieldValues, UseControllerProps } from 'react-hook-form/dist/types';
import FormLabel from '../../form/FormLabel/FormLabel';
import styles from './FormCheckbox.module.scss';

type Props<T extends FieldValues> = {
  label?: string;
  items: { value: string; child: React.ReactNode }[];
  required?: boolean;
  onChange?: () => void;
} & UseControllerProps<T>;

function FormCheckbox<T extends FieldValues>({ name, items, label, control, onChange }: Props<T>) {
  const {
    field,
    fieldState: { error },
  } = useController<T>({ name, control, defaultValue: [] as T[typeof name] });

  return (
    <div className={formStyle.formComponentStyle}>
      {/* ラベル */}
      {label && <FormLabel>{label}</FormLabel>}
      {/* フィールドブロック */}
      <div className={error && formStyle.hasError}>
        <div className={styles.checkboxes}>
          {items.map((item) => (
            <InputCheckbox
              key={item.value}
              name={field.name}
              inputRef={field.ref}
              checked={field.value.includes(item.value)}
              onChange={(e) => {
                if ((e.target as HTMLInputElement).checked) {
                  field.onChange(field.value.concat([item.value]));
                } else {
                  field.onChange(field.value.filter((fv: string) => fv !== item.value));
                }
                if (onChange) {
                  onChange();
                }
              }}
              inputValue={item.value}
            >
              {item.child}
            </InputCheckbox>
          ))}
        </div>
        {/* エラー */}
        {error && <p className={formStyle.errorMessage}>{(error as DeepMap<FieldValues, FieldError>).message}</p>}
      </div>
    </div>
  );
}
export default FormCheckbox;
